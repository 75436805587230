import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@/components/layouts/Layout'
import { FaqMediumCategory, Faq } from '@/interfaces/faq'
import styled from 'styled-components'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { theme } from '@/styles/theme'
import { Seo } from '@/components/Seo'
import { ListItems } from '@/components/layouts/ListItems'
import { MainWrapper, TitleWrapper } from '@/styles/common'

export const query = graphql`
  query ($faqMediumCategoryId: String!) {
    microcmsFaqMediumCategory(
      faqMediumCategoryId: { eq: $faqMediumCategoryId }
    ) {
      faqMediumCategoryId
      mediumCategory
    }
    allMicrocmsFaq(
      filter: { mediumCategory: { id: { eq: $faqMediumCategoryId } } }
    ) {
      edges {
        node {
          question
          faqId
        }
      }
    }
  }
`

type Query = {
  microcmsFaqMediumCategory: Pick<
    FaqMediumCategory,
    'faqMediumCategoryId' | 'mediumCategory'
  >
  allMicrocmsFaq: {
    edges: [
      {
        node: Pick<Faq, 'question' | 'faqId'>
      }
    ]
  }
}

export default function mediumCategory({ data }: { data: Query }) {
  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'よくあるご質問',
      path: '/faq/',
    },
    {
      name: data.microcmsFaqMediumCategory.mediumCategory,
    },
  ]

  return (
    <Layout appHide>
      <Seo
        title={`${data.microcmsFaqMediumCategory.mediumCategory} - よくあるご質問`}
        canonical={`https://su-pay.jp/faq/${data.microcmsFaqMediumCategory.faqMediumCategoryId}/`}
      />
      <Title as="h1" bg={true}>
        よくあるご質問
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            {data.microcmsFaqMediumCategory.mediumCategory}
          </Title>
        </TitleWrapper>
        <div>
          <ul>
            {data.allMicrocmsFaq.edges.map((faq) => (
              <ListItems
                key={faq.node.faqId}
                link={`/faq/${data.microcmsFaqMediumCategory.faqMediumCategoryId}/${faq.node.faqId}/`}
                item={faq.node.question}
              />
            ))}
          </ul>
        </div>
      </MainWrapper>
    </Layout>
  )
}
